<template>

    <v-container  >
      <template>
          <div>
            <v-card>
              <v-responsive :aspect-ratio="16/9" max-height="100%">
                <template>
  <v-row  >
    <v-col
      cols="12"
      sm="8"
      md="6"
    >
      <v-card  
    class="overflow-y-auto"
    max-height="900"> 
    <v-list dense>
          <v-subheader class="text-h5" >Mis Links</v-subheader>
          <v-list-item-group 
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            > 
              <v-list-item-content  @click="dataLink(item)"> 

                 
                  <v-list-item class="text-end  grey--text"  > 
                    <v-list-item-title   >{{ item.createdAt }}</v-list-item-title>
                    <v-list-item-icon class="text-end  ">
                          <v-icon class="text-end  grey--text">mdi-clock-time-two-outline</v-icon>
                      </v-list-item-icon> 
                  </v-list-item> 
            
                <v-list-item-title class="text-start body-1" >Descripcion:</v-list-item-title>
                <v-list-item-title class="text-start  grey--text"  v-text="item.descripcion"></v-list-item-title>
                <v-list-item-title class="text-start  body-1" >Link Original :</v-list-item-title>
                <v-list-item-title class="text-start  grey--text"  v-text="item.link_original"></v-list-item-title>
                <v-list-item-title class="text-start  body-1" >Link Generado :</v-list-item-title>
                <v-list-item-title class="text-start  grey--text"  v-text="item.link_generado"></v-list-item-title>

                <v-list-item class="text-end  white--text" disabled> 
                    <v-list-item-title class="text-h6 font-weight-medium " >{{   item.visitas }}</v-list-item-title>
                    <v-list-item-icon class="text-end  ">
                          <v-icon class="text-end white--text">mdi-cursor-default-click-outline</v-icon>
                      </v-list-item-icon> 
                  </v-list-item> 
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="8"
      md="6"
      class="align-self-center"
    >
    <template>
        <v-card
          class="mx-auto align-content-center"
          max-width="100%"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
               Información
              </v-list-item-title>
              <v-list-item-subtitle>{{editedItem.createdAt}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-col     v-if="editedItem.qr==true" > 
               <qrcode-vue :value="ruta" :size="pixeles" level="Q" ></qrcode-vue>
          </v-col>
    
          <v-col
            cols="12" 
          >
            <v-text-field
              v-model = "editedItem.palabra_clave" 
              label="Palabra Clave"
              v-if="editedItem.id>0" 
              outlined   
              ref="focusinput"
              :readonly="modolectura"
            ></v-text-field> 
            <v-autocomplete
              v-model="modelOrigen"
              class="mr-3"
              style="margin-top: 1.5em;"
              :items="itemsOrigen"  
               
              color="white"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="id"
              label="Origen de Link"
              placeholder="Origen de Link" 
              return-object
              @change="seleccionAsistente"
            ></v-autocomplete>
            <v-text-field
              v-model = "editedItem.descripcion" 
              label="Descripción" 
              outlined    
            ></v-text-field>
            <v-text-field
            v-model = "editedItem.link_original" 
              label="Link Original"
              
              outlined
              :readonly="modolecturaEditar"
            ></v-text-field> 
            <v-text-field
            v-model = "editedItem.link_generado" 
              label="Link Generado"
              v-if="editedItem.id>0" 
              :append-icon=" 'mdi-content-copy' "
              @click:append="copiarLink"
              outlined
              :readonly="modolectura"
            ></v-text-field>   
            <v-col
            cols="12" 
          >
            <v-row>
            <v-switch
                v-model="editedItem.qr"
                label="Incluir QR"
                color="orange darken-3"
                hide-details
              ></v-switch> 
          </v-row>
        </v-col>
          </v-col>
          

          <v-row>
            <v-col
              cols="6" 
            >
              <v-text-field
              v-model = "editedItem.visitas" 
                label="Visitas"
                outlined
                readonly
                v-if="editedItem.id>0" 
              ></v-text-field>
            </v-col>
            <v-col
              cols="6" 
            >
            <v-select
              v-model="comboSelecto"
              :items="combo"
              v-if="editedItem.id>0" 
              item-value="status"
              item-text="descripcion"
              label="Estatus Link"
              outlined
              return-object
              @change="seleccionarStatus"
            ></v-select>
            </v-col>
          </v-row>
 

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text @click="limpiarData" :loading="isLoading" >
              Limpiar
            </v-btn>
            <v-btn text @click="editarLink" v-if="editedItem.id>0" >
              Editar
            </v-btn>
            <v-btn text @click="verEstadisticas" v-if="editedItem.id>0" >
              Estadisticas
            </v-btn>
            <v-btn text @click="guardarCambios" v-if="desbloquearEditar" :loading="isLoading">
              Guardar
            </v-btn>
            <v-btn text @click="guardarCambios" v-if="editedItem.id==0" :loading="isLoading">
              Generar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>
                <v-divider
  vertical
></v-divider> 
              </v-responsive>
            </v-card>
          </div>
      </template>


      <v-snackbar
        :color="alertaModalsColor"
        v-model="alertaModals"
        :multi-line="true"
        :timeout="5000"
        centered
        outlined
        
      >
        {{ textAlertModals }}
  
        <template   >
          <v-btn 
          :color="alertaModalsColor"
            text  
            elevation="24"
             @click="alertaModals = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
   <!--OTRO MODAL-->
   <v-dialog
          ref="myModal"
          v-model="modalClicks"
          max-width="750px" 
          persistent 
          @input="onModalShown"
        >
           
          <v-card>
            <v-card-title>
              <span class="text-h5">Clicks por dia</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-card      > 
  
  <canvas   height="250px"  id="myChart2"></canvas>
</v-card>
              </v-container>
            </v-card-text>
             
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modalClicks=false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <!--OTRO MODAL-->

      </v-container>


 
   
  </template>
  <script> 
  import ApiService from '../../service/apis'
  import QrcodeVue from 'qrcode.vue'
  import Chart from 'chart.js/auto';
  import moment from 'moment' 
  export default {
    name:'Links',
    data: () => ({ 
      isLoading:false,
      modalClicks:false,
      comboSelecto:null,
      modolectura:true,
      links :[{id:1,label:'https://sdigna.com/'},{id:4,label:'http://uimpct.com/'},{id:3,label:'https://sdigna.com/'}],
      modelOrigen :{id:1,label:'Mexico - https://sdigna.com'} ,
      itemsOrigen :[{id:1,label:'Mexico - https://sdigna.com'},{id:4,label:'PetCT - http://uimpct.com'} ],
      modolecturaEditar:false,
      desbloquearEditar :false,  
      enviosmes :[],
      labelsmes :[],
      dialogFull:true,  
      textAlertModals:'',
      alertaModalsColor : '',
      alertaModals : false,  
      frase: null,
      origen:null, 
      pixeles:250,
      chart : undefined,
      ruta:null,
      sesion:JSON.parse(sessionStorage.getItem('sesion')),
      combo: [{status:1, descripcion:'Activo'},{status:0, descripcion:'Inactivo'}],
      editedItem: {
        id:0,
        palabra_clave: '',
        link_original: '',
        link_generado: '', 
        visitas:0,
        status:0,
        createdAt : '',
        descripcion:'',
        qr:0,
        modelOrigen :{id:1,label:'Mexico - https://sdigna.com'} 
      },
      defaultItem: {
        id:0,
        palabra_clave: '',
        link_original: '',
        link_generado: '', 
        visitas:0,
        status:0,
        createdAt : '',
        descripcion:'',
        qr:0,
        modelOrigen :{id:1,label:'Mexico - https://sdigna.com'} 
      },
      items: []
      }), 
      components: {
        QrcodeVue,
      },
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Limpieza de base de correos'  : 'Documentos' 
        },
      },
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
      created () { 
        this.getLinks()
      }, 
      updated(){ 
      },
      methods: {
        getLinks(){    
          ApiService.getLinks( this.sesion.id).then(links =>{

            links.lista.forEach(element => { 
              moment.locale('es-mx')
                element.createdAt = moment(element.createdAt ).format('LLL')
 
                element.link_generado = this.links.filter(e=>e.id == element.origen)[0].label+element.link_generado 
            })

            this.items = links.lista 
          })
        },
        copiarLink(){ 
         this.mensajeAlertaModals('success','Elemento en portapapeles..')
         navigator.clipboard.writeText(this.editedItem.link_generado)
        },
        dataLink(data){ 
        //  console.log(this.itemsOrigen.filter(e=>e.id == data.origen))
          this.editedItem =  data
          this.ruta = this.editedItem.link_generado=this.links.filter(e=>e.id == data.origen)[0].label+this.editedItem.palabra_clave 
          this.frase = this.editedItem.palabra_clave.replaceAll(' ','%20')
          this.modolectura = true
          this.comboSelecto =data.status==1? {status:1, descripcion:'Activo'}:{status:0, descripcion:'Inactivo'}
          this.modelOrigen  = this.itemsOrigen.filter(e=>e.id == data.origen)[0]
        },
        seleccionarStatus(){
          console.log(this.comboSelecto)
        },
        editarLink(){
          this.modolectura =this.modolectura == true ? false : true   
          this.modolecturaEditar =false
          this.desbloquearEditar =true
          this.$refs["focusinput"].focus()
          
        },
        verEstadisticas(){  
          if(this.editedItem.id>0){

            this.modalClicks = this.modalClicks == true ? false : true     
            this.$nextTick(() => {
             this.onModalShown()
            
            })
          } 
          
        },
        onModalShown() {  
          
          this.labelsmes=[]
          this.enviosmes=[]
          ApiService.reporteLinks( this.editedItem.id).then(links =>{ 
            links.result.forEach(element => {
              this.labelsmes.push(element.fecha)
              this.enviosmes.push(element.visitas)
            })
            
           const ctx2 = document.getElementById('myChart2') 
           if (typeof this.chart !== 'undefined') {
            // Si existe, destrúyelo antes de crear uno nuevo
            this.chart.destroy();
          }

            this.chart = new Chart(ctx2, {
                    type: 'bar',
                    data: {
                        labels: this.labelsmes ,
                        datasets: [{
                            label: 'Clicks por dia',
                            data: this.enviosmes,
                            axis: 'x',
                            fill: false,
                            backgroundColor: [
                            '#e53935',
                            '#d81b60',
                            '#8e24aa',
                            '#5e35b1',
                            '#3949ab',
                            '#1e88e5',
                            '#039be5',
                            '#00acc1',
                            '#00897b',
                            '#43a047',
                            '#7cb342',
                            '#c0ca33',
                            '#fdd835',
                            '#ffb300',
                            '#fb8c00',
                            '#f4511e',
                            '#6d4c41',
                            '#757575',
                            '#546e7a',
                            '#bdbdbd',
                            '#bdbdbc',
                            '#bdbdb1'
                            ],
                            borderColor: [
                                'white' 
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: { 
                        indexAxis: 'x' 
                    }
              })
          })
            
           
        
          
        },
        guardarCambios(){
          this.isLoading =true  
          this.editedItem.origen = this.modelOrigen.id


          if(this.editedItem.id==0){
            
            if(this.editedItem.link_original.length<5 || this.editedItem.link_original.descripcion<5){
              this.mensajeAlertaModals('error','La descripcion y el enlace no pueden estar vacios..')
              this.isLoading =false
            }else{
              this.editedItem.capturista_id = this.sesion.id
              ApiService.crearLink(this.editedItem).then(r=>{  
                if(r.status==true){
                  this.mensajeAlertaModals('success','Se genero un nuevo link..') 
                }else{
                  this.mensajeAlertaModals('error','Ocurrio un error al intentar generar el link, favor de probar nuevamente..') 
                }
                this.isLoading =false
              }).finally(()=>{
                this.getLinks()
                this.limpiarData()
              })
            }

          }else{
             
              this.editedItem.capturista_id = this.sesion.id
              ApiService.updateLink(this.editedItem).then(r=>{  
                if(r.status==true){
                  this.mensajeAlertaModals('success','Se actualizaron los datos..') 
                }else{
                  this.mensajeAlertaModals('error','Ocurrio un error al intentar actualizar la informacion, favor de probar nuevamente..') 
                }
                this.isLoading =false
              }).finally(()=>{
                this.getLinks() 
                this.editedItem.link_generado="https://sdigna.com/"+this.editedItem.palabra_clave 
                
              }) 
          }
          
          
        },
        limpiarData(){ 
          this.editedItem = this.defaultItem
          this.editedItem.descripcion = ''
          this.editedItem.link_original = ''
          this.comboSelecto = null
          this.modelOrigen =this.defaultItem.modelOrigen
        },
        mensajeAlertaModals(tipo,texto){
        this.alertaModalsColor =tipo
        this.alertaModals =true
        this.textAlertModals =texto
        }
      },
  }
  </script>
  <style>
  .v-application .headline {
    letter-spacing: unset;
  } 
  </style>